import { abortNavigation, defineNuxtRouteMiddleware, useNuxtApp } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { fetchMenuAuthApi } from '@/apis/member.api';
import { showAlert } from '@/composables/useDialog';
import { MENU_ACCESS_DENY_ERROR_CODE } from '@/constants/error.const';
import { ERROR_PAGE_NAME } from '@/constants/url.const';
import { useAppStore } from '@/stores/app.store';
import { useUserStore } from '@/stores/user.store';
import { isDirectAccess, redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const { name: toName, meta } = to;
    const { name: fromName } = from;

    const userStore = useUserStore();
    const { isMenuWritable } = storeToRefs(useAppStore());
    const { selectedGroupId, isOwner } = storeToRefs(userStore);

    const menuAuthId = meta.menuAuthId as string;
    const auth = await fetchMenuAuthApi({
      groupId: selectedGroupId.value,
      menuAuthorityId: menuAuthId,
      isOwner: isOwner.value
    });
    if (auth.isWritable || auth.isReadable) {
      isMenuWritable.value = auth.isWritable;
      return;
    }

    isMenuWritable.value = false;

    const msg = 'studio.common.popup_case_g_not_view';

    const nuxtApp = useNuxtApp();
    const t = (nuxtApp.$i18n as any)?.t;

    if (isDirectAccess(toName, fromName)) {
      return await redirectTo(ERROR_PAGE_NAME, {
        toType: 'name',
        query: { code: MENU_ACCESS_DENY_ERROR_CODE }
      });
    }
    await showAlert({ content: t(msg) });
    return abortNavigation();
  }
);
